import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase
} from "../components/headers/light.js";
import Footer from "../components/footer/MiniCenteredFooter";
import Submit from "../components/forms/SimpleContactUs.js";
import logoImageSrc from "../../src/images/logo-light.svg";


const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-black text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-appgreen lg:hocus:bg-appgreen`;

export default (props) => {
  let content = "";
  let contentTitle = "";
  let location = useLocation();
  console.log(location.pathname);
  if(location.pathname.includes('terms')){
    content=props.terms;
    contentTitle= "Blackible - Terms of Service";
  }else if(location.pathname.includes('about')){
    content=props.about;
    contentTitle= "Blackible - About";
  }else if(location.pathname.includes('privacy')){
    content=props.privacy;
    contentTitle= "Blackible - Privacy Policy";
  }
  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" />
      Blackible
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/about">About</NavLink>
      <PrimaryLink href="/submit">Submit</PrimaryLink>
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer>
      <AnimationRevealPage>
        <Header logoLink={logoLink} links={navLinks} />
        <div style={{color:'white',fontStyle:'bold'}} dangerouslySetInnerHTML={{ __html: props.content }} />
            {/* <div> */}
          <Footer />
      </AnimationRevealPage>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{contentTitle}</title>
            <meta name="description" content="Discover black-owned businesses in your city" />
        </Helmet>
    </PrimaryBackgroundContainer>
  );
}
