import React from 'react';
import "tailwindcss/dist/base.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Blackible from "./pages/BlackibleMain";
import Submit from "./pages/Submit";
import "../src/styles/globalStyles.css";
import logo from './logo.svg';
import './App.css';
import Pages from './pages/Pages';
import appConfig, {privacy, about, terms} from"../src/app-config";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/submit">
          <Submit/>
        </Route>
        <Route path="/about">
          <Pages content={about}/>
        </Route>
        <Route path="/terms">
          <Pages content={terms}/>
        </Route>
        <Route path="/privacy">
          <Pages content={privacy}/>  
        </Route>
        <Route path="/">
          <Blackible/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
